import React from 'react'
import styled, { keyframes } from 'styled-components'
import * as PropTypes from 'prop-types'

const LoadingCircle = ({ className, ...props }) => {
  return <StyledLoadingCircle className={`submit-loading-circle ${className}`} {...props} />
}

LoadingCircle.propTypes = {
  className: PropTypes.string,
}

LoadingCircle.defaultProps = {
  className: '',
}

const spinAnimation = keyframes`
    100% {
        transform: rotate(360deg);
    }
`

const StyledLoadingCircle = styled.div`
  animation: ${spinAnimation} 1s infinite linear;
  border: 4px solid rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  border-top-color: rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  height: 30px;
  width: 30px;
`

export default LoadingCircle
