import React, { useEffect, useState, useCallback } from 'react'
import * as PropTypes from 'prop-types'
import noop from 'utils/noop'

const Select = ({
  children,
  className,
  label,
  name,
  onChange,
  placeholder,
  required,
  updateValidationSet,
  ...props
}) => {
  const [displayingError, setDisplayingError] = useState(false)
  const [error, setError] = useState(false)
  const [value, setValue] = useState('')

  required = required ? 'required' : ''

  const validate = useCallback(() => {
    let err = false
    let isValid = true

    if (!value && required) {
      err = 'This field is required.'
      isValid = false
    }

    setError(err)
    updateValidationSet(name, isValid)
  }, [value, required, name, updateValidationSet])

  useEffect(() => {
    validate()
  }, [value, required, validate])

  const handleChange = e => {
    setValue(e.target.value)
    setError(false)
    setDisplayingError(false)
    onChange(e)
  }

  const handleBlur = () => {
    setDisplayingError(!!error)
  }
  return (
    <label className={`${displayingError ? 'error' : ''} ${required} ${className}`}>
      <span className='labelText'>{label}</span>
      <select
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        required={required}
        name={name}
        placeholder={placeholder}
        className={value ? 'filled' : ''}
        {...props}
      >
        {children}
      </select>
      {displayingError && <div className='msg'>{error}</div>}
    </label>
  )
}

Select.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  updateValidationSet: PropTypes.func,
}

Select.defaultProps = {
  className: '',
  onChange: noop,
  placeholder: '',
  required: false,
  updateValidationSet: noop,
}

export default Select
