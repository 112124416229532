import React, { useCallback, useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import noop from 'utils/noop'

const Input = ({
  className,
  label,
  name,
  onChange,
  placeholder,
  required,
  type,
  updateValidationSet,
  validationError,
  ...props
}) => {
  const [displayingError, setDisplayingError] = useState(false)
  const [error, setError] = useState(false)
  const [value, setValue] = useState('')

  required = required ? 'required' : ''

  const validate = useCallback(() => {
    let err = false
    let isValid = true

    if (!value && required) {
      err = 'This field is required.'
      isValid = false
    }

    setError(err)
    updateValidationSet(name, isValid)
  }, [value, required, name, updateValidationSet])

  useEffect(() => {
    validate()
  }, [value, required, validate])

  const handleChange = e => {
    setValue(e.target.value)
    setError(false)
    setDisplayingError(false)
    onChange(e)
  }

  const handleBlur = () => {
    setDisplayingError(!!error)
  }
  return (
    <label className={`${displayingError ? 'error' : ''} ${required} ${className}`}>
      <span className='labelText'>{label}</span>
      <input
        type={type}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        required={required}
        name={name}
        placeholder={placeholder}
        className={value ? 'filled' : ''}
        {...props}
      />
      {displayingError && <div className='msg'>{error}</div>}
    </label>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    'button',
    'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'image',
    'month',
    'number',
    'password',
    'radio',
    'range',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
    'week',
  ]),
  updateValidationSet: PropTypes.func,
  validationError: PropTypes.string,
}

Input.defaultProps = {
  className: '',
  onChange: noop,
  placeholder: '',
  required: false,
  type: 'text',
  updateValidationSet: noop,
  validationError: '',
}

export default Input
