import React, { useCallback, useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import isValidEmail from 'utils/is-valid-email'
import noop from 'utils/noop'

const Email = ({
  className,
  label,
  name,
  onChange,
  placeholder,
  required,
  updateValidationSet,
  ...props
}) => {
  const [displayingError, setDisplayingError] = useState(false)
  const [error, setError] = useState(false)
  const [value, setValue] = useState('')

  required = required ? 'required' : ''

  const validate = useCallback(() => {
    let err = false
    let isValid = true

    if (required) {
      if (!value) {
        err = 'This field is required.'
        isValid = false
      } else if (!isValidEmail(value)) {
        err = 'Please enter a valid email.'
        isValid = false
      }
    }

    setError(err)
    updateValidationSet(name, isValid)
  }, [value, required, name, updateValidationSet])

  useEffect(() => {
    validate()
  }, [value, required, validate])

  const handleChange = e => {
    setValue(e.target.value)
    setError(false)
    setDisplayingError(false)
    onChange(e)
  }

  const handleBlur = () => {
    setDisplayingError(!!error)
  }
  return (
    <label className={`${displayingError ? 'error' : ''} ${required} ${className}`}>
      <span className='labelText'>{label}</span>
      <input
        type='email'
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        required={required}
        name={name}
        placeholder={placeholder}
        className={value ? 'filled' : ''}
        {...props}
      />
      {displayingError && <div className='msg'>{error}</div>}
    </label>
  )
}

Email.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  updateValidationSet: PropTypes.func,
}

Email.defaultProps = {
  className: '',
  label: 'Email',
  name: 'Email',
  onChange: noop,
  placeholder: '',
  required: false,
  updateValidationSet: noop,
}

export default Email
