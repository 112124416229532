import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import Form from 'react-netlify-form'
import { Submit } from 'components/forms/elements/v2/submit'
import Spacer from 'components/new/spacer'
import Email from 'components/forms/elements/email'
import Phone from 'components/forms/elements/phone'
import Input from 'components/forms/elements/input'
import Location from 'components/forms/elements/location'
import Textarea from 'components/forms/elements/textarea'
import ResponseMessage from 'components/forms/elements/response-message'
import { Column, Grid } from 'components/grid'
import { useFormValidation } from 'hooks/use-form-validation'
import { useLocation } from '@reach/router'

const ProductLineForm = ({ productLine }) => {
  const location = useLocation()
  const [valid, updateValidationSet] = useFormValidation({})
  const [started, setStarted] = useState(false)
  return (
    <Form name='Product Line'>
      {({ loading, error, success }) => (
        <div>
          {success && (
            <ResponseMessage
              message='Success!'
              messageBody='Your form has been submitted. Our team will be in touch with you soon!'
            />
          )}
          {error && (
            <ResponseMessage
              error={true}
              message='Error Occurred.'
              messageBody={
                'There was an issue submitting your form. If problems persist please contact <a href="mailto:contactus@hutsoninc.com">our support team</a>.'
              }
            />
          )}
          {!success && (
            <div onFocus={() => setStarted(true)} role='none'>
              <Grid>
                <Column columns='1/2'>
                  <Input
                    updateValidationSet={updateValidationSet}
                    label='First Name'
                    name='First Name'
                    validationError='Please enter a valid first name.'
                    required
                  />
                </Column>
                <Column columns='1/2'>
                  <Input
                    updateValidationSet={updateValidationSet}
                    label='Last Name'
                    name='Last Name'
                    validationError='Please enter a valid last name.'
                    required
                  />
                </Column>
              </Grid>
              <Grid>
                <Column columns='2/3'>
                  <Email updateValidationSet={updateValidationSet} required />
                </Column>
                <Column columns='1/3'>
                  <Phone updateValidationSet={updateValidationSet} required />
                </Column>
              </Grid>
              <Grid>
                <Column columns='1/1'>
                  <Location
                    updateValidationSet={updateValidationSet}
                    label='Nearest Location'
                    name='Nearest Location'
                    required
                  />
                </Column>
              </Grid>
              <Textarea
                updateValidationSet={updateValidationSet}
                label='Message'
                name='Message'
                validationError='Please enter a valid message.'
              />
              <div style={{ display: 'none' }}>
                <label>
                  Product Line
                  <input type='text' name='Product Line' value={productLine} readOnly />
                </label>
                <label>
                  Page URL
                  <input type='text' name='Page URL' value={location.href} readOnly />
                </label>
              </div>
              <Spacer size='m' variable />
              <Submit
                className='submit-question'
                disabled={(!valid && started) || success}
                loading={loading}
              />
            </div>
          )}
        </div>
      )}
    </Form>
  )
}

ProductLineForm.propTypes = {
  productLine: PropTypes.string.isRequired,
}

export default ProductLineForm
