import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const ResponseMessage = ({ className, error, message, messageBody }) => {
  return (
    <Container error={error} className={className}>
      <Message>
        <Title>{message}</Title>
        &nbsp;
        <Details>{messageBody}</Details>
      </Message>
    </Container>
  )
}

ResponseMessage.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  message: PropTypes.string,
  messageBody: PropTypes.node,
}

ResponseMessage.defaultProps = {
  className: '',
  error: false,
  message: 'Success!',
  messageBody: 'Your form has been submitted. Our team will be in touch with you soon!',
}

const Container = styled.div`
  background-color: ${props => (props.error ? 'hsl(0deg 100% 95%)' : 'hsl(120deg 100% 95%)')};
  border: ${props =>
    props.error ? '1px solid hsl(0deg 100% 33.73%)' : '1px solid hsl(120deg 100% 33.73%)'};
  margin: 20px 0;
  padding: 10px 20px;
  width: 100%;
`

const Message = styled.div`
  color: #000;
  font-size: 1em;
  margin: 0;
  padding: 0;
  line-height: 1.65;
  text-align: left;
  text-transform: none;
  vertical-align: bottom;
`

const Title = styled.span`
  font-weight: bold;
  vertical-align: bottom;
`

const Details = styled.span`
  vertical-align: bottom;

  a {
    color: #000;
    display: inline;
    font-size: 1em;
    line-height: 1.65;
    text-decoration: underline;
    vertical-align: bottom;
  }
`

export default ResponseMessage
