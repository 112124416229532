import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import LoadingCircle from 'components/loading-circle'
import Button from 'components/button/button'

export const Submit = ({ as, disabled, loading, value, ...props }) => {
  return (
    <React.Fragment>
      <Button
        as={StyledInput}
        value={value}
        type='submit'
        disabled={disabled || loading}
        {...props}
      />
      {loading && (
        <LoadingContainer>
          <LoadingCircle />
        </LoadingContainer>
      )}
    </React.Fragment>
  )
}

Submit.propTypes = {
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  value: PropTypes.string.isRequired,
}

Submit.defaultProps = {
  as: 'input',
  disabled: false,
  loading: false,
  value: 'Submit',
}

const StyledInput = styled.input`
  display: inline-block;
`

const LoadingContainer = styled.div`
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
`
