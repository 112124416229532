import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

/**
 * @typedef SeoProps
 * @property {Object[]} [breadcrumbs]
 * @property {string} breadcrumbs[].name
 * @property {string} breadcrumbs[].link
 * @property {string} [canonicalUrl]
 * @property {import('react').ReactNode} [children]
 * @property {string} [description]
 * @property {string} [image] - Image URL or image path
 * @property {string[]} [keywords]
 * @property {string} [ogDescription]
 * @property {string} [ogImage]
 * @property {string} [ogTitle]
 * @property {string} [ogType]
 * @property {string} [title]
 * @property {string} [titleOverride] - Title to override the template
 * @property {string} [url]
 */

/**
 * @typedef SeoStaticQueryResult
 * @property {Object} site
 * @property {Object} site.siteMetadata
 * @property {string} site.siteMetadata.defaultTitle
 * @property {string} site.siteMetadata.description
 * @property {string} site.siteMetadata.image - Default image path
 * @property {string} site.siteMetadata.siteUrl
 * @property {string} site.siteMetadata.twitterUsername
 */

/**
 * @param {SeoProps} props
 */
export const Seo = props => {
  const { pathname } = useLocation()

  /**
   * @type {SeoStaticQueryResult}
   */
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          defaultTitle: title
          description
          image
          siteUrl
          twitterUsername
        }
      }
    }
  `)

  const {
    breadcrumbs,
    canonicalUrl,
    children,
    defaultTitle,
    description,
    image,
    keywords,
    ogDescription,
    ogImage,
    ogTitle,
    ogType,
    siteUrl,
    title,
    titleOverride,
    twitterCard = 'summary',
    twitterUsername,
    url,
  } = {
    ...siteMetadata,
    ...props,
  }

  const pageTitle = titleOverride
    ? titleOverride
    : title
    ? `${title} | ${defaultTitle}`
    : defaultTitle

  const fallbackUrl = `${siteUrl}${pathname}`

  const pageImage = /^\//.test(image) ? `${siteUrl}${image}` : image

  return (
    <Helmet title={pageTitle}>
      <meta name='description' content={description} />

      {Array.isArray(keywords) && keywords.length > 0 ? (
        <meta name='keywords' content={keywords.join(', ')} />
      ) : null}

      <meta name='image' content={pageImage} />

      <meta property='og:url' content={url || fallbackUrl} />
      <meta property='og:title' content={ogTitle || pageTitle} />
      <meta property='og:description' content={ogDescription || description} />
      <meta property='og:image' content={ogImage || pageImage} />
      {ogType ? <meta property='og:type' content={ogType} /> : null}

      <meta name='twitter:card' content={twitterCard} />
      <meta name='twitter:site' content={twitterUsername} />
      <meta name='twitter:title' content={ogTitle || pageTitle} />
      <meta name='twitter:description' content={ogDescription || description} />
      <meta name='twitter:image' content={ogImage || pageImage} />

      {Array.isArray(breadcrumbs) && breadcrumbs.length > 0 ? (
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': breadcrumbs.map((breadcrumb, index) => {
              return {
                '@type': 'ListItem',
                'position': index + 1,
                'name': breadcrumb.name,
                'item': `${siteUrl}${breadcrumb.link}`,
              }
            }),
          })}
        </script>
      ) : null}

      <link rel='canonical' href={canonicalUrl || url || fallbackUrl} />

      {children}
    </Helmet>
  )
}
