import { useState } from 'react'

export const useFormValidation = initialState => {
  const [valid, setValid] = useState(false)
  const [state, setState] = useState(initialState)

  const validate = newState => {
    const keys = Object.keys(newState)
    let newValid = true
    for (let i = 0; i < keys.length; i++) {
      if (!newState[keys[i]]) {
        newValid = false
        break
      }
    }
    setValid(newValid)
  }

  const updateValidationSet = (name, isValid) => {
    const newState = {
      ...state,
    }

    if (!(name in newState)) {
      newState[name] = isValid
      setState(newState)
      validate(newState)
    }

    if (state[name] !== isValid) {
      newState[name] = isValid
      setState(newState)
      validate(newState)
    }
  }

  const remove = names => {
    if (!Array.isArray(names)) {
      names = [names]
    }
    let newState = {
      ...state,
    }
    names.forEach(name => {
      delete newState[name]
    })
    setState(newState)
    validate(newState)
  }

  return [
    valid,
    updateValidationSet,
    {
      remove,
      state,
    },
  ]
}
