import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { clearfix } from 'styles/clearfix'
import { column } from 'styles/column'

const AlternatingGrid = ({ align, content, direction, gutter, ratio, ...props }) => {
  return (
    <Container direction={direction} {...props}>
      {content.map((node, key) => (
        <Column
          columns={key === 0 ? ratio : flipRatio(ratio)}
          gutter={gutter}
          align={align}
          direction={direction}
          key={key}
        >
          {node}
        </Column>
      ))}
    </Container>
  )
}

AlternatingGrid.propTypes = {
  align: PropTypes.oneOf(['top', 'middle', 'bottom']).isRequired,
  content: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  direction: PropTypes.oneOf(['ltr', 'rtl']).isRequired,
  gutter: PropTypes.number.isRequired,
  ratio: PropTypes.string.isRequired,
}

AlternatingGrid.defaultProps = {
  align: 'middle',
  direction: 'ltr',
  gutter: 0,
  ratio: '1/2',
}

const flipRatio = ratio => {
  ratio = ratio.split('/')
  let numerator = Number(ratio[0])
  let denominator = Number(ratio[1])
  numerator = denominator - numerator
  return `${numerator}/${denominator}`
}

const Container = styled.div`
  @media (min-width: 900px) {
    direction: ${props => props.direction};
    ${clearfix}
  }
`

const Column = styled.div`
  @media (min-width: 900px) {
    direction: ${props => props.direction};
    display: inline-block;
    float: none !important;
    margin: 0;
    vertical-align: ${props => props.align};
    width: auto;
    ${props => column(props.columns, props.gutter)}

    :nth-child(even) {
      direction: rtl;
    }
  }
`

export default AlternatingGrid
