import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import isDefined from 'utils/is-defined'
import Layout from 'components/layout'
import Content from 'components/new/content'
import AlternatingGrid from 'components/alternating-grid'
import Breadcrumbs from 'components/breadcrumbs'
import Form from 'components/forms/product-line'
import Spacer from '~/components/new/spacer'
import { Seo } from 'components/seo'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const ProductLineTemplate = props => {
  const {
    data: { sanityBrand },
  } = props

  const breadcrumbs = [
    {
      displayName: 'Home',
      name: 'Hutson Inc',
      link: '/',
    },
    {
      name: 'Product Lines',
      link: '/product-lines/',
    },
    {
      name: sanityBrand.name,
      link: sanityBrand.slug,
    },
  ]

  const metaDescription =
    isDefined(sanityBrand.meta) && isDefined(sanityBrand.meta.description)
      ? sanityBrand.meta.description
      : `Hutson Inc is an official ${sanityBrand.name} dealer. We carry a variety of ${sanityBrand.name} products.`
  return (
    <Layout kind='white'>
      <Seo breadcrumbs={breadcrumbs} description={metaDescription} title={sanityBrand.name} />

      <Content kind='full'>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <AlternatingGrid
          content={[
            <LogoContainer>
              <GatsbyImage image={sanityBrand.logo.asset.gatsbyImageData} alt={sanityBrand.name} />
            </LogoContainer>,
            <DetailsContainer>
              <h1>{sanityBrand.name}</h1>
              <OutboundLink
                href={sanityBrand.manufacturersWebsite}
                className='manufacturer-website'
              >
                Visit manufacturer website
              </OutboundLink>
              {sanityBrand.description ? <p>{sanityBrand.description}</p> : null}
              <Spacer size='l' />
              {Array.isArray(sanityBrand.products) && sanityBrand.products.length > 0 ? (
                <React.Fragment>
                  <h2>{sanityBrand.name} products at Hutson</h2>
                  <ul>
                    {sanityBrand.products.map(product => (
                      <li key={product}>{product}</li>
                    ))}
                  </ul>
                </React.Fragment>
              ) : null}
            </DetailsContainer>,
          ]}
          direction='rtl'
          ratio='1/4'
          align='top'
        />
        <QuestionsH2>Questions about {sanityBrand.name} products?</QuestionsH2>
        <Form productLine={sanityBrand.name} />
      </Content>
    </Layout>
  )
}

const QuestionsH2 = styled.h2`
  font-size: 1.5em;
  margin: 32px 0 16px;
`

const LogoContainer = styled.div`
  direction: ltr;
  margin-bottom: 30px;
  max-width: 320px;

  img {
    width: 100%;
  }

  @media (min-width: 900px) {
    max-width: none;
  }
`

const DetailsContainer = styled.div`
  direction: ltr;
  padding-right: 30px;

  h1 {
    font-size: 2em;
    margin: 0;
    padding: 0;
  }

  .manufacturer-website {
    color: #377539;
    display: inline-block;
    font-size: 0.95em;
    margin-top: 8px;
  }

  ul {
    margin-top: 15px;
  }
`

export const pageQuery = graphql`
  query productLine($handle: String!) {
    sanityBrand(handle: { eq: $handle }) {
      description
      handle
      logo {
        asset {
          gatsbyImageData(width: 320)
        }
      }
      manufacturersWebsite
      meta {
        description
      }
      name
      products
      slug
    }
  }
`

export default ProductLineTemplate
